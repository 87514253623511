@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600&display=swap");
$ff-primary:  'Quicksand', sans-serif;
$secondary-color: #48a0a5;
$gradient: linear-gradient(-45deg, #f4af9a 0%, #f38599 100%);

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: $ff-primary;
    color: #ffffff;
}


h2 {
    font-family: Quicksand;
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

h3 {
    font-family: Quicksand;
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

p {
    font-size: 20px;
    margin-bottom: 2rem ;
    font-family: Quicksand;
}

*:focus {
    outline: none;
}

input {
    font-size: 16px;
}

.text-center {
    text-align: center;
}