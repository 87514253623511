.container {
    max-width: 1120px;
}

.section {
    padding: 6rem 1.3rem;
    @media (min-width: 1281px) {
        padding: 6rem 4rem;
    }
}


.primary-background {
    background-image: $gradient; 
}

.color-black {
    color: black !important;
}

.reverse { 
    display:flex;
  flex-direction: column-reverse;
}

.relative {
    position: relative;
}

.line {
    border-radius: 0.5px;
    border: 1px solid #ffffff;
    margin: 1.8rem 0;
}

.bold {
    font-weight: bold;
}

.text-center {
    text-align: center !important;
}

.justify-center {
    justify-content: center !important;
}


.margin-0-auto {
    margin: 0 auto;
}
