.button {
    background-color: $secondary-color;
    border: none;
    padding: 0.8rem 2.3rem;
    cursor: pointer;
    border-radius: 0.6rem;
    color: white;
    font-family: "Quicksand";
    font-weight: 600;
    font-size: 1.0625rem;
    box-shadow: 0 8px 40px -8px rgba(0, 0, 0, 0.2);

  
        text-decoration: none;
    
}