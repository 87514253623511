#modal-open {
    padding: 10px;
    border: 1px solid #fff;
    border-radius: 5px;
    cursor: pointer;
    background-color: transparent;
    font-size: 18px;
    color: #fff;
  }

  #modal-container, #yt-modal-container {
     
    z-index: 100;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0rem;
    left: 50%;
    background-color: none;
    transform: translate(-50%, -100%);
    opacity: 0;
    transition: .6s ease-in-out;

  }
  
  #modal-container.open, #yt-modal-container.open{
    transform: translate(-50%, 0);
    opacity: 1;
  }
  
  #modal-content, #yt-modal-content {
      position: relative;
      top: 1.5rem;
      width: 90%;
    max-width: 750px;
    margin: 10px auto;
    padding: 30px 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 8px 40px -8px rgba(0, 0, 0, 0.2);


    @media (min-width: 800px) {
        padding: 60px 80px;
    }
  }
  
  .modal-close {
      position: absolute;
      right: 10px;
      top: 5px;
     font-size: 18px;
      border-radius: 1px;
    color: #333;
    cursor: pointer;
  }

  .modal-logo {
    border-radius: 10px;
    display: inline-block;
    margin: 2rem 0 1rem 0;

    img {
        width: 75px; 
    }
  }

  .modal-content {
      text-align: center;
      color: black;
      .title {
          color: black;
          font-weight: bold;
      }

      p {
        font-size: 15px;
        font-weight: 500;
      }
  }


  #yt-modal-content {
    padding: 1.5rem;
  }