.giveaway {
    position: relative;
    overflow: hidden;
    margin-top: 5rem;
    border-radius: 10px;
    box-shadow: 0 8px 40px -8px rgba(0, 0, 0, 0.2);
    background-image: linear-gradient(293deg, #f4af9a, #f38599);
    padding: 2rem 1rem 3.3rem 1rem;

    

   * {
    position: relative;
   }

   p {
    font-size: 20px;
    position: relative;
    max-width: 1020px;
   }

   &-title {
    font-weight: bold;
    margin: 0;
    padding: 0;
    font-size: 35px;
}

    &-subtitle {
        margin-top: 0;
        font-size: 20px;
        font-weight: 500;
        font-size: 20px;
        
    }

    &-form {
        position: relative;
        display: block;
        max-width: 30rem;
        margin: 0 auto;
    
        input {
            margin-right: 0.6rem;
            margin-bottom: 1rem;
        }
        input, button {
           width: 100%;
            display: block;
        }
        
    }

    .circle1 {
        width: 400px;
        height: 400px;
        bottom:  -93px;
        left:  -25px;
    }

    .circle2 {
        width: 20px;
        height: 20px;
        top: 55px;
        left: 300px;
    }

    .circle3 {
        width: 410px;
        height: 410px;
        right: -8px;
        top: -190px;
    }

    @media (min-width: 800px) {
        padding: 2rem 2rem 3.3rem 2rem;

        text-align: center;

        p {
            font-size: 25px;
          
           }
        &-title {

            font-size: 50px;
        }

        &-subtitle {
            font-size: 30px;
        }

        &-form {
            display: flex;

            input {
                margin-bottom: 0;
            }

            input, button {
                
                width: 48%;
                display: inline-block;
            }
        }
      
    }
}