.header {
    margin-bottom: 40px;
    width: 100%;
    background-image: $gradient; 
    background-size: auto 100%;
    position: relative;
    clip-path: polygon(0 0, 100% 0, 100% 95%, 0% 100%);
    overflow: hidden;
    

    &-action {
        position: absolute;
        left: 0;
        top: 0;
    }

    &--nav {
        margin: 1.5rem  1.2rem 0 1.2rem;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    &-content {
        position: relative;
        padding: 5rem 0;
        @media (min-width: 800px) {
            justify-content: space-between;
            display: flex;
            padding: 0;
        }
    }

    &-app-preview {
        display: none;
        img {
            max-width: 170px;
        }

        .img1 {
            position: absolute;
            right: 9rem;
            top: 0;
        }
        .img2 {
            position: relative;
            top: 3rem;
            left: 2rem;
            padding-bottom: 4rem;
            margin-right: 2rem;
        }

        @media (min-width: 800px) {
            display: block;
        }
    }

    &-logo {
        display: none;
        @media (min-width: 800px) {
            width: 185px;
            display: block;
        }
    }
    
    .circle1 {
        width: 20px;
        height: 20px;
        top: 588px;
        left: 1008px;
    }
    
    .circle4 {
        width: 18px;
        height: 18px;
        top: 130px;
        left: 808px;
    }
    
    .circle5 {
        width: 10px;
        height: 10px;
        top: 150px;
        left: 830px;
    }
    
    .circle6 {
        top: 100px;
        right: 100px;
        width: 22px;
        height: 22px;
    }
    
    .circle2 {
        width: 742px;
        height: 742px;
        top: 40px;
        left: 80px;
    }
    
    .circle3 {
        width: 442px;
        height: 442px;
        top: 40px;
        right: 200px;
    }
    
}
