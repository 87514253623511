.img-slider {
    padding-top: 1rem;
    img {
        max-height: 38rem;
        width: auto !important;
    }
}

.swipe {
    overflow: hidden;
    visibility: hidden;
    position: relative;
}
.swipe-wrap {
    overflow: hidden;
    position: relative;
}
.swipe-wrap > div {
    float: left;
    width: 100%;
    position: relative;
    overflow: hidden;
}

@media (min-width: 800px) {
    .img-slider {
        display: none;
    }
}
