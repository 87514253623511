@import "base";
@import "helpers";
@import "components/header";
@import "components/footer";
@import "components/button";
@import "components/input";
@import "components/modal";
@import "components/img-slider";
@import "components/giveaway";

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600&display=swap");

.circle {
    background-image: $gradient;
    border-radius: 50%;
    position: absolute;
}

.header-txt {
    max-width: 590px;
    font-family: Quicksand;
    font-size: 35px;
    font-weight: 600;
    color: #ffffff;

    @media (min-width: 800px) {
        font-size: 45px;
    }
}

.guide {
    margin: 0 auto;

    &-text {
        max-width: 766px;
        color: black;
        font-size: 30px;
        font-weight: 600;
        text-align: center;
        color: #000000;
        padding-bottom: 2rem;

        @media (min-width: 800px) {
            padding-bottom: 10rem;
        }
    }

    &-info {
        display: block;

        @media (min-width: 800px) {
            display: flex;
        }
    }

    &-img-vertial {
        width: 156px;
        display: none;

        transition: opacity 0.6s ease;

        &:last-child {
            margin-bottom: 3.4rem;
        }

        @media (min-width: 800px) {
            display: block;
        }
    }
    &-img {
        width: 336px;
    }

    &-box {
        margin: 5rem 0;
        color: black;
        padding-left: 0;
        

        &:first-child {
            margin: 0;
        }

        h3 {
            font-size: 20px;
            font-weight: 600;
            margin: 0.8rem 0;
            color: black;
        }

        p {
            font-size: 15px;
            font-weight: 500;
            max-width: 480px;
            margin-bottom: 3.2rem;
        }

        &-icon {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 70px;
            height: 70px;
            border-radius: 10px;
            margin: 1rem 0;
        }

        .active {
            background-image: linear-gradient(315deg, #f4af9a, #f38599);
        }

        .icon-1 {
            box-shadow: 0 8px 40px -8px rgba(0, 0, 0, 0.2);
        }
        .icon-2,
        .icon-3 {
            box-shadow: 0 8px 40px -8px rgba(0, 0, 0, 0.2);
            background-color: #ffffff;
        }

    }

    &-section {
        display: block;
        .app {
            width: 100%;
        }

    

        @media (min-width: 800px) {
            display: block;
            padding-right: 3rem;
            &:last-child {
                padding-right: 0;
            }
        }
    }

    .main-app  {
      
            transition: opacity 0.6s ease;
        
    }

    .section-vertical {
        display: none;

        @media (min-width: 800px) {
            display: block;
        }
    }

    @media (min-width: 800px) {
        &-text {
            font-size: 50px;
        }
    }
}

.center-section {
    display: table;
    clip-path: polygon(0 0, 100% 5%, 100% 100%, 0 95%);

    &-content {
        display: table-header-group;
    }

    &-images {
        width: 100%;
        display: table-footer-group;
       
        display: inline-block;
        max-width: 800px;

        margin: 4rem 0;

        .img-container {
            position: relative;
            display: inline-block;
        }

        img {
            display: inline-block;
            width: 100px;
        }

        .img1 {
            width: 160px;
            height: 160px;
            position: absolute;
            z-index: 100;
            border-radius: 20px;
            top: -40px;
            right: -55px;
            object-fit: cover;
        }

        .img1-right {
            top: auto;
            bottom: -30px;
            width: 160px;
            height: 160px;
        }

        .img2 {
            width: 160px;
            height: 160px;

            position: absolute;
            z-index: 100;
            border-radius: 20px;
            bottom: -20px;
            left: 0;
            object-fit: cover;
        }

        .middle-img {
            width: 280px;
            height: 280px;          
            object-fit: cover;
            border-radius: 20px;
            position: relative;
            top: -10px;
            left: 0;

            


            @media (min-width: 800px) {
                width: 400px;
                height: 400px;
                left: auto;
                position: relative;
            }
        }

         .left {
             left: 55px;
             @media (min-width: 800px) {
                left: 0;
            }
            }

        .right {
            right: 80px;
        }

        .middle-img-2 {
            width: 280px;
            height: 280px;
            object-fit: cover;
            border-radius: 20px;


            @media (max-width: 359px) {
                width: 240px;
                height: 240px;   
            }

            @media (min-width: 800px) {
                width: 450px;
                height: 450px;
            }
        }
    }
}

.video-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    min-height: 350px;
}
.video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#app-container {
    display: none;
}

.dots {
   width: 100%;
   display: block;
   padding: 1.5rem 0;
    .dot {
        margin: 0 0.4rem;
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: #f38599;
        border-radius: 50%;

    
    }


    .active {
        width: 15px;
        height: 15px;
    }
}

#all-u-need {
    position: relative;
    overflow: hidden;

    .container {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .info {
        position: relative;

        * {
            position: relative;
        }
    }
  
    .circle1 {
        width: 22px;
        height: 22px;
        bottom: 100px;
        left: 30px
    }

    .circle2 {
        width: 17px;
        height: 17px;
        top: 200px;
        left: 200px
    }

    .circle3 {
        width: 634px;
        height: 634px;
        bottom: -170px;
        left: 100px
        
    }
}


.click-here {
    display: none;
    color: #f38599;
    font-size: 15px;
    position: absolute;
    font-family: Quicksand;
    top: 13rem;
    font-weight: bold;
    right: - 0.3rem;

    .rotate {
        display: inline-block;
        transform: rotate(-16deg);
    }

    img {
        position: absolute;
        bottom: 0.3rem;
        left: 5.3rem;
    }

    @media (min-width: 800px) {
        display: block;
    }
  
}

@media (min-width: 800px) {
    .center-section {
        display: block;
        &-content {
            display: inline-block;
           

            p, h2 {
                max-width: 480px;
            }
        }

        &-content-right {
            padding-right: 0;
            padding-left: 9rem;
        }

        &-images {
            width: auto;
            text-align: center;
            .img1 {
                width: 250px;
                height: 250px;
                object-position: bottom;
                top: -40px;
                right: 0
                
            }

            .img2 {
                width: 250px;
                height: 250px;
                bottom: -20px;
                left: -80px;
            }

            .img1-right {
                top: auto;
                right: -75px;
                width: 250px;
                height: 250px;

            }
        }

        .reverse {
            flex-direction: unset;
        }
    }

    .flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    #app-container {
        padding-right: 6.4rem;
        display: block;
    }


.steps {
    display: block;
}


#all-u-need {
    .container {
        margin-top: 0;
        margin-bottom: 0;
    }
    
}

}

@media (max-width: 768px) {
    .center-section {
        button {
            width: 100%;
        }
    }
}

.bookings {
    * {
        color: black; 
    }

    .imgs-container  {
        padding: 40px 0;
        display: none;
        @media (min-width: 800px) {
          display: block;
        }
    }

    .title {
        max-width: 766px;
        margin: 0 auto 1.5rem auto;
        font-weight: 600;
        font-size: 30px;
        @media (min-width: 800px) {
            margin: 0 auto;
            font-size: 50px;
          }
    }

    img {
        margin: 0 1rem;
    }

    .small-img {
        max-width: 150px;
        bottom: 105px;
        position: relative;
    }

    .medium-img {
        bottom: 85px;
        position: relative;
        max-width: 170px;
    }

    .big-img {
        max-width: 250px;

    }

    .description {
       .text {
        margin: 1rem auto 0 auto;
        max-width: 680px;
       }

       @media (min-width: 800px) {
        margin: 0 auto;
      }
    }

    text-align: center;
}