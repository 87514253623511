.footer {
    background-image: $gradient; 
    background-size: auto 100%;
    width: 100%;
    .line {
        margin: 1rem 0;
    }

    a {
        color: white;
        text-decoration: none;
    }

    ul {
        margin-top: 1.5rem;
        list-style-type: none;

        li {
            margin: 1rem 0;
        }
    }

    .section {
        padding-bottom: 3rem;
    }

    .social {
        margin-top: 3rem;
        &-icon {
            cursor: pointer;
            width: 30px;
            margin: 0 1.1rem;

            &:first-child {
                margin-left: -0.5rem;
            }
        }
        
    }
}